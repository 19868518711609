<template>
  <div>
    <NuxtLayout
      name="default"
      :is-primary-navbar-sticky="false"
      apply-now-type="link"
      :apply-now-url="programData?.applyNowUrl"
      :rfi-anchor-id="pageData.rfiSectionId"
    >
      <!-- section-introduction -->
      <HeroDegreeAtlas
        id="program-intro"
        :title="programData?.alternateTitle!"
        title-level="h1"
        title-size="medium"
        :bg-image-source="programData?.degreeImage!"
        :altText="programData?.degreeImageAlt!"
        :next-start-date="programData?.nextStartDate!"
        :info-list="generateInfoList"
        link-text="Learn more"
        link-url="#program-detail"
        @onLinkClick="
          scrollToDetail($event, programData?.alternateTitle!, 'program-detail')
        "
      >
        <template #aboveContent>
          <Alert v-if="checkDate()" show variant="info">
            <div class="d-flex flex-row align-items-center">
              <font-awesome-icon
                :icon="['fa', 'info-circle']"
                class="alert-icon"
              ></font-awesome-icon>
              <span class="fs-small fw-bold">
                {{ programData?.webinar?.message + " " }}
                <a
                  href="#webinar-info"
                  class="rds-link"
                  @click="
                    scrollToDetail(
                      $event,
                      programData?.webinar?.message!,
                      'webinar-info'
                    )
                  "
                  >Learn more</a
                >.
              </span>
            </div>
          </Alert>
        </template>
        <template #text>
          <div v-html="programData?.shortDescription"></div>
        </template>
      </HeroDegreeAtlas>

      <!-- section-rfi -->
      <SectionRfiDegree
        id="program-rfi"
        :career="programData?.category?.title!"
        :program-id="programData?.id!"
        :program-name="programData?.title!"
      ></SectionRfiDegree>

      <!-- section-secondary-navbar -->
      <NavbarStickyAtlas :nav-items="navbarItems">
        <template #stuckMenu>
          <div class="d-flex justify-content-end">
            <ButtonApplyNow
              type="link"
              :link-url="(programData?.applyNowUrl as string)"
              event-region="main content"
              event-section="secondary navbar"
            ></ButtonApplyNow>

            <a
              class="btn btn-secondary fs-medium fw-bold ms-space-sm"
              href="#program-rfi"
              @click="smoothScroll"
            >
              Request Info
            </a>
          </div>
        </template>
      </NavbarStickyAtlas>

      <!-- section-detail -->
      <SectionDetail
        id="program-detail"
        :title="(programData?.longDescriptionTitle as string)"
        :description="(programData?.longDescription as string)"
      ></SectionDetail>

      <!-- section-custom-block -->
      <section
        v-if="programData?.customBlockTitle || programData?.diplomaTitle"
        class="pb-space-xl pb-lg-space-xxl"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 offset-lg-1 col-lg-10">
              <CardInfoHorizontal
                v-if="programData.customBlockTitle"
                border-variant="light-2"
                :bg-image-source="(programData.customBlockImage as string)"
                :display-label="false"
                :title="programData.customBlockTitle"
                title-size="large"
                :display-cta="false"
              >
                <div
                  class="mb-space-sm mb-lg-space-md"
                  v-html="programData.customBlockDescription"
                ></div>
              </CardInfoHorizontal>

              <!-- section-diploma-info -->
              <CardInfoHorizontal
                v-if="programData.diplomaTitle"
                border-variant="light-2"
                bg-image-source="//live-asuocms.ws.asu.edu/sites/default/files/image-section-diploma-info.jpg"
                :display-label="false"
                :title="programData.diplomaTitle"
                title-size="large"
                :display-cta="false"
                class="mt-space-md"
              >
                <div
                  class="mb-space-sm mb-lg-space-md"
                  v-html="programData.diplomaDescription"
                ></div>
              </CardInfoHorizontal>
            </div>
          </div>
        </div>
      </section>

      <!-- section webinar info -->
      <SectionIntroFalcon
        id="webinar-info"
        v-if="checkDate()"
        :title="programData?.webinar!.title!"
        title-variant="light-1"
        section-background-variant="light-2"
        container-bg-color="dark-3"
        image-position="right"
        :image-source="programData?.webinar!.image!"
      >
        <template #body>
          <div
            v-html="programData?.webinar!.description"
            class="text-light-1 mb-space-sm"
          ></div>
          <div class="mb-space-md">
            <!-- put webinar date and time here -->
            <ul class="text-light-1 rds-list rds-list-secondary">
              <li class="mb-space-xs">
                <span class="fw-bold">Date: </span
                >{{
                  utilityService.formatWebinarDate(
                    programData?.webinar?.webinarDate!,
                    "date"
                  )
                }}
              </li>
              <li>
                <span class="fw-bold">Time: </span
                >{{
                  utilityService.formatWebinarDate(
                    programData?.webinar?.webinarDate!,
                    "time"
                  )
                }}
                (Arizona time)
              </li>
            </ul>
          </div>
          <a
            :href="programData?.webinar?.link?.uri!"
            class="btn btn-sm btn-secondary px-space-xs py-space-xxs"
            @click="
              handleWebinarBtnClick(
                $event,
                programData?.webinar?.title!,
                programData?.webinar?.link as LinkType
              )
            "
          >
            {{ programData?.webinar?.link?.text }}
          </a>
        </template>
      </SectionIntroFalcon>

      <!-- section-video -->
      <SectionVideoModal
        v-if="programData?.videoTitle"
        :title="programData?.videoTitle"
        :video-source="programData?.videoUrl!"
        bg-image-source="//live-asuocms.ws.asu.edu/sites/default/files/2020-06/Ignite-66_mobile.jpg"
        @modal-open="
          analyticsComposable.trackModalEvent(
            'onclick',
            'open',
            'click',
            'main content',
            'details',
            'watch video',
            'video'
          )
        "
        @modal-close="
          analyticsComposable.trackModalEvent(
            'onclick',
            'close',
            'click',
            'main content',
            'details',
            'youtube video',
            'video'
          )
        "
      ></SectionVideoModal>

      <!-- section-featured-courses -->
      <SectionUndergradFeaturedCourses
        v-if="
          programData?.curriculumCourse?.courseItems &&
          JSON.parse(programData?.curriculumCourse.courseItems).length > 0
        "
        id="program-courses"
        :class="{ 'pb-lg-space-xl': !displayCareers }"
        :title="programData?.featuredCoursesTitle!"
        :description="programData?.featuredCoursesDescription!"
        :bamm-program="(programData?.bammProgram as any)"
        :courses="programData?.curriculumCourse.courseItems"
      ></SectionUndergradFeaturedCourses>

      <!-- section-related-careers -->
      <section-undergrad-related-careers
        v-if="displayCareers"
        id="program-careers"
        class="mb-lg-space-xxl"
        :title="programData?.relatedCareersTitle!"
        :description="programData?.relatedCareersDescription!"
        :image-source="programData?.relatedCareersImage!"
        :careers="programData?.bammProgram?.careerItems!"
      ></section-undergrad-related-careers>

      <!-- section-prestigious-faculty -->
      <SectionParallaxApollo
        class="pb-space-md pb-lg-0"
        :title="programData?.prestigiousFacultyTitle!"
        title-size="large"
        :bg-image-source="programData?.facultyImage!"
        :display-cta="false"
      >
        <div
          v-html="programData?.prestigiousFacultyDescription"
          class="mb-space-sm mb-lg-space-md"
        ></div>
      </SectionParallaxApollo>

      <!-- section-admission-requirements -->
      <SectionUndergradAdmissionRequirements
        id="program-admissions"
        :display-override-requirements="programData?.admissionRequirementOverride!"
        :title="programData?.admissionRequirementTitle!"
        :description="programData?.admissionRequirementDescription!"
        :items="(programData?.admissionRequirementItems as any)"
        event-component="admission requirements"
      ></SectionUndergradAdmissionRequirements>

      <!-- section-earned-admission -->
      <SectionParallaxApollo
        class="pb-space-lg pb-lg-0"
        bg-image-source="//live-asuocms.ws.asu.edu/sites/default/files/2020-05/200207-Hayden-Library-Remodel-4248-JO-%281%29.jpg"
        title-level="h2"
        title-size="small"
        :title="pageData.earnedAdmission.title"
        :display-cta="pageData.earnedAdmission.displayCta"
        lg-columns="6"
        offset-lg-columns="3"
        :highlight-width="8"
      >
        <div
          v-html="pageData.earnedAdmission.text"
          class="mb-space-sm mb-lg-space-md"
        ></div>
      </SectionParallaxApollo>

      <!-- section-accolades -->
      <SectionProgramAccolades
        id="program-accolades"
        class="pt-0 pb-space-xxs px-lg-space-xxxl pt-lg-space-xxl mb-0"
        :title="programData?.accoladesTitle!"
        :description="programData?.accoladesDescription"
        :items="(programData?.accoladeItems as any)"
      ></SectionProgramAccolades>

      <!-- section-cost -->

      <SectionCost
        id="program-cost"
        class="pt-space-xxl"
        :title="pageData.sectionCost.title"
        :text="pageData.sectionCost.description"
        :program-category="programData?.category?.title!"
        :program-code="programData?.programCode!"
        :cost-override="programData?.costOverride!"
        :cost-academic-year="programData?.costAcademicYear!"
        :fee-code-resident="programData?.resProgramFeeCode!"
        :fee-code-non-resident="programData.noresProgramFeeCode!"
        :cost-collection="(programData?.costCollection as any)"
        degree-type="Undergraduate"
      ></SectionCost>

      <!-- section-faq -->
      <SectionCollapseItems
        v-if="
          programData?.faqTitle &&
          programData?.faqItems &&
          programData?.faqItems?.length > 0
        "
        id="program-faq"
        :title="programData?.faqTitle"
        :collapse-items="(programData?.faqItems as any)"
        event-component="faq"
      ></SectionCollapseItems>

      <!-- section-interested-programs -->
      <SectionInterestedPrograms
        :degrees="(programData?.interestedPrograms as DegreeSeekingProgram[])"
      ></SectionInterestedPrograms>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import Alert from "@/components/Alert.vue";
import SectionCollapseItems from "@/components/SectionCollapseItems.vue";
import HeroDegreeAtlas from "@/components/degree-page/HeroDegreeAtlas.vue";
import SectionCost from "@/components/degree-page/SectionCost.vue";
import SectionDetail from "@/components/degree-page/SectionDetail.vue";
import SectionInterestedPrograms from "@/components/degree-page/SectionInterestedPrograms.vue";
import SectionProgramAccolades from "@/components/degree-page/SectionProgramAccolades.vue";
import SectionUndergradAdmissionRequirements from "@/components/degree-page/SectionUndergradAdmissionRequirements.vue";
import SectionUndergradFeaturedCourses from "@/components/degree-page/SectionUndergradFeaturedCourses.vue";
import SectionUndergradRelatedCareers from "@/components/degree-page/SectionUndergradRelatedCareers.vue";
import ButtonApplyNow from "@/components/nav/ButtonApplyNow.vue";
import SectionRfiDegree from "@/components/rfi/SectionRfiDegree.vue";
import type {
  DegreeSeekingProgram,
  ProgramExploratory,
  ProgramUndergraduate,
} from "@/server/resolvers/resolvers-types";
import { scrollToTarget } from "@/utils";
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { SectionVideoModal } from "@rds-vue-ui/section-video-modal";
import { useWindowSize } from "@vueuse/core";

interface PageQueryType {
  programUndergraduate: ProgramUndergraduate;
  programExploratory: ProgramExploratory;
}
const route = useRoute();
const data = await usePageQuery<PageQueryType>(
  `#graphql
      query ProgramUndergraduate($slug: String!){
        programUndergraduate(slug: $slug){
          id
          slug
          detailPage
          code
          programCode
          title
          alternateTitle
          shortDescription
          longDescriptionTitle
          longDescription
          weeksPerClass
          totalClasses
          totalCreditHours
          nextStartDate
          applyNowUrl
          metaTagTitle
          metaTagDescription
          prestigiousFacultyTitle
          prestigiousFacultyDescription
          facultyImage
          videoTitle
          videoUrl
          degreeImage
          degreeImageAlt
          category{
            title
          }
          interestAreas{
            title
          }
          featuredCoursesTitle
          featuredCoursesDescription
          curriculumCourse{
            courseItems
          }
          relatedCareersTitle
          relatedCareersDescription
          relatedCareersImage
          bammProgram{
            majorMapUrl
            asuCritTrackUrl
            careerItems
          }
          admissionRequirementOverride
          admissionRequirementTitle
          admissionRequirementDescription
          admissionRequirementItems{
            title
            description
          }
          accoladesTitle
          accoladesDescription
          accoladeItems{
            title
            description
          }
          faqTitle
          faqDescription
          faqItems{
            title
            description
          }
          customBlockTitle
          customBlockDescription
          customBlockImage
          costOverride
          costAcademicYear
          resProgramFeeCode
          noresProgramFeeCode
          costCollection{
            amount
            summary
          }
          interestedPrograms{
            id
            title
            nextStartDate
            category{
              title
            }
            detailPage
            degreeImage
          }
          diplomaTitle
          diplomaDescription
          webinar {
            title
            message
            image
            description
            webinarDate
            link {
              text
              target
              uri
            }
          }
        }
        programExploratory(slug: $slug){
          id
          slug
          detailPage
          code
          programCode
          title
          alternateTitle
          shortDescription
          longDescriptionTitle
          longDescription
          weeksPerClass
          totalClasses
          totalCreditHours
          nextStartDate
          applyNowUrl
          metaTagTitle
          metaTagDescription
          prestigiousFacultyTitle
          prestigiousFacultyDescription
          facultyImage
          videoTitle
          videoUrl
          degreeImage
          degreeImageAlt
          category{
            title
          }
          interestAreas{
            title
          }
          featuredCoursesTitle
          featuredCoursesDescription
          curriculumCourse{
            courseItems
          }
          bammProgram{
            majorMapUrl
            careerItems
          }
          admissionRequirementOverride
          admissionRequirementTitle
          admissionRequirementDescription
          admissionRequirementItems{
            title
            description
          }
          accoladesTitle
          accoladesDescription
          accoladeItems{
            title
            description
          }
          faqTitle
          faqDescription
          faqItems{
            title
            description
          }
          customBlockTitle
          customBlockDescription
          customBlockImage
          costOverride
          costAcademicYear
          resProgramFeeCode
          noresProgramFeeCode
          costCollection{
            amount
            summary
          }
          interestedPrograms{
            id
            title
            nextStartDate
            category{
              title
            }
            detailPage
            degreeImage
          }
          diplomaTitle
          diplomaDescription
          webinar {
            title
            message
            image
            description
            webinarDate
            link {
              text
              target
              uri
            }
          }
        }
      }
    `,
  {
    slug: route.params.slug as string,
  },
  {
    key: (route.params.slug as string) + "-undergrad-data",
  }
);

type ProgramType = ProgramUndergraduate | ProgramExploratory;

const programData = ref<ProgramType>(
  data?.programUndergraduate !== null
    ? data?.programUndergraduate!
    : data?.programExploratory
);

const config = useRuntimeConfig();
const siteUrl = config.public.siteUrl;
const utilityService = useUtilityService();
const schemaService = useSchemaService();
const { width } = useWindowSize();
try {
  const JSONLdSchema = schemaService.generateProgramTemplateSchema(
    `${siteUrl}${programData.value?.detailPage}`,
    programData.value,
    [],
    "undergraduate"
  );
  useHead({
    title: programData.value?.metaTagTitle,
    meta: [
      { name: "Title", content: programData.value?.metaTagTitle },
      {
        name: "Description",
        content: programData.value?.metaTagDescription,
      },
      ...utilityService.generateSocialTags(
        programData.value?.metaTagTitle!,
        programData.value?.metaTagDescription!,
        programData.value?.degreeImage!
      ),
    ],
    link: [
      {
        rel: "canonical",
        href: `${siteUrl}${programData.value?.detailPage}`,
      },
    ],
    script: [JSONLdSchema],
  });
} catch (e) {
  console.error("=== Error in undergraduate slug");
  console.error(e);
}

definePageMeta({
  layout: false,
  __dangerouslyDisableSanitizersByTagID: {
    "ldjson-schema": ["innerHTML"],
  },
});

const pageData = reactive({
  rfiSectionId: "program-rfi",
  earnedAdmission: {
    title: "Don't meet admission requirements?",
    text: "<p>You can still gain general admission to most online programs at ASU through Earned Admission. Through this pathway, you can demonstrate your ability to succeed at ASU by completing online courses with a 2.75 GPA or higher.</p><p>To begin, submit an application to ASU. An enrollment coach will reach out with more information if Earned Admission is right for you.</p><p>*Some programs may have higher admission requirements. You must meet all program requirements to be admitted.</p>",
    displayCta: false,
  },
  sectionCost: {
    title: "Tuition calculator",
    description:
      '<p>Use our calculator to estimate your full-time or part-time tuition fees for this program prior to any financial aid. Keep in mind that most of our students receive financial aid, which can reduce out-of-pocket costs. <a href="/what-it-costs/financial-aid/" class="non-rds-link">Learn more.</a></p>',
  },
});

const displayCareers = computed(() => {
  return (
    programData.value?.bammProgram?.careerItems &&
    JSON.parse(programData.value?.bammProgram.careerItems).length > 0 &&
    programData.value?.relatedCareersImage
  );
});

const navbarItems = computed(() => {
  interface NavItemsContent {
    [key: string]: string;
  }

  let navbarItems: NavItemsContent = {};

  navbarItems["program-intro"] = "Overview";
  navbarItems["program-detail"] = "Details";

  if (
    programData.value?.curriculumCourse?.courseItems &&
    JSON.parse(programData.value?.curriculumCourse.courseItems).length > 0
  ) {
    navbarItems["program-courses"] = "Courses";
  }

  if (displayCareers.value) {
    navbarItems["program-careers"] = "Careers";
  }

  navbarItems["program-admissions"] = "Admissions";
  navbarItems["program-accolades"] = "Accolades";
  navbarItems["program-cost"] = "Tuition";

  return navbarItems;
});
const generateInfoList = computed(() => {
  return [
    {
      value: programData.value?.totalClasses!,
      description: "Total classes: ",
    },
    {
      value: programData.value?.weeksPerClass!,
      description: "Weeks per class: ",
    },
    {
      value: programData.value?.totalCreditHours!,
      description: "Total credit hours: ",
    },
  ];
});

const scrollToDetail = (e: Event, section: string, target: string) => {
  e.preventDefault();

  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    section.toLowerCase(),
    "learn more"
  );

  let navbarOffset = 59;
  if (width.value < 992) {
    navbarOffset = 65;
  }

  scrollToTarget(target, { offset: navbarOffset });
};

const smoothScroll = (e: Event) => {
  e.preventDefault();
  const eTarget = e.target as HTMLLinkElement;
  const eventText = eTarget.textContent
    ? eTarget.textContent.toLowerCase()
    : "N/A";
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    "secondary navbar",
    eventText,
    "secondary navbar"
  );
  let navbarOffset = 59;
  if (width.value < 992) {
    navbarOffset = 65;
  }
  const href = eTarget.getAttribute("href");

  scrollToTarget(href?.slice(1), { offset: navbarOffset });
};
const checkDate = () => {
  if (programData.value?.webinar) {
    const currentTime = new Date(new Date().toDateString()).getTime();
    const webinarDate = new Date(
      new Date(programData.value!.webinar.webinarDate!).toDateString()
    ).getTime();
    const checkTime = webinarDate - currentTime >= 0;
    return checkTime;
  }
  return false;
};

interface LinkType {
  text: string;
  uri: string;
}
const handleWebinarBtnClick = async (
  e: Event,
  section: string,
  link: LinkType
) => {
  e.preventDefault();

  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    section.toLowerCase(),
    link.text.toLowerCase()
  );

  await navigateTo(link.uri, {
    open: {
      target: "_blank",
    },
  });
};
</script>

<style scoped lang="scss">
.alert-icon {
  font-size: 2rem;
  margin-right: 24px;
}

:deep(.bg-backdrop) {
  margin-top: 0px;
}
</style>
